import { QuestionnaireType } from "@/core/types/questionnaires";
import http from "@/services/http";
import { QuestionShortType } from "@/core/types/questions";
import { CategoryType } from "@/core/category-type";
import { CreateQuestionDto } from "@/modules/client/models/questionnaires/create-question.dto";

const resource = `${process.env.VUE_APP_API_URL}questionnaires/`;

const fetchQuestionnaireCategories = () =>
  http.get(`${resource}questionnaire_categories/`);

const fetchQuestionsCategories = (): Promise<Array<CategoryType>> =>
  http.get(`${resource}question_categories/`);

const fetchQuestionnaires = (
  category?: string
): Promise<Array<QuestionnaireType>> =>
  http.get(`${resource}questionnaires/`, { params: { category } });

const fetchQuestions = (query: {
  [key: string]: string | number;
}): Promise<Array<QuestionShortType>> =>
  http.get(`${resource}questions/`, { params: query });

const cloneQuestionnaire = (
  id: number,
  name: string
): Promise<{ id: number }> =>
  http.post(`${resource}questionnaires/${id}/clone/`, { name });

const submitForApproval = (id: number): Promise<any> =>
    http.post(`${resource}questionnaires/${id}/submit_for_approval/`, {});

const deleteQuestionnaire = (id: number): Promise<void> =>
  http.delete(`${resource}questionnaires/${id}/`);

const editQuestionnaire = (id: number, data: { name: string }): Promise<void> =>
  http.put(`${resource}questionnaires/${id}/`, data);

const addQuestionToQuestionnaire = (
  questionnaireId: number,
  data: CreateQuestionDto
): Promise<QuestionShortType> =>
  http.post(
    `${resource}client_questions/?questionnaire_id=${questionnaireId}`,
    data
  );

const updateQuestionInQuestionnaire = (
  questionnaireId: number,
  questionId: number,
  data: CreateQuestionDto
): Promise<QuestionShortType> =>
  http.put(
    `${resource}client_questions/${questionId}/?questionnaire_id=${questionnaireId}`,
    data
  );

const deleteQuestionFromQuestionnaire = (
  questionnaireId: number,
  questionId: number
): Promise<void> =>
  http.delete(
    `${resource}client_questions/${questionId}/?questionnaire_id=${questionnaireId}`
  );


const exportQuestionnaire = (id: number) =>
  http.get(`${resource}questionnaires/${id}/export/`);

export default {
  fetchQuestionnaireCategories,
  fetchQuestionsCategories,
  fetchQuestionnaires,
  fetchQuestions,
  cloneQuestionnaire,
  deleteQuestionnaire,
  editQuestionnaire,
  addQuestionToQuestionnaire,
  updateQuestionInQuestionnaire,
  deleteQuestionFromQuestionnaire,
  submitForApproval,
  exportQuestionnaire,
};
