export enum QuestionnaireStatusesEnum {
  Created = "CREATED",
  Submitted = "SUBMITTED",
  Approved = "APPROVED",
}

export const QuestionnaireStatuses = {
  [QuestionnaireStatusesEnum.Created]: "Created",
  [QuestionnaireStatusesEnum.Submitted]: "Submitted for",
  [QuestionnaireStatusesEnum.Approved]: "Approved",
}
